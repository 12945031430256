// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";

// Core variables and mixins overrides
@import "../../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../core/variables/components-variables";

// toast container opacity

#toast-container > div {
  //opacity: 0.9;
  //-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  //filter: alpha(opacity=90);
  max-width: none;
}

// toast container hover shadow

// toastify colors

.Toastify__toast-container {
  & > div {
    //opacity: 0.95;
    //-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=95);
    //filter: alpha(opacity=95);
    max-width: none;
    &:hover {
      -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
    }
  }

  &.theme-danger{
    .Toastify__toast--success {
      background-color: $danger !important;
    }
  }
  &.theme-warning{
    .Toastify__toast--success {
      background-color: $warning !important;
    }
  }
  &.theme-info{
    .Toastify__toast--success {
      background-color: $info !important;
    }
  }
  &.theme-success{
    .Toastify__toast--success {
      background-color: $success !important;
    }
  }
  &.theme-dark{
    .Toastify__toast--success {
      background-color: $dark !important;
    }
  }

  .Toastify__toast--success {
    background: $success;
  }

  .Toastify__toast--info {
    background: $info;
  }

  .Toastify__toast--warning {
    background-color: $warning;
  }

  .Toastify__toast--error {
    background: $danger;
  }

  .Toastify__toast-body {
    font-weight: 600;
    margin-right: 5px;
    svg {
      flex-grow: 0;
      margin-right: 5px;
    }
    span {
      color: $white;
    }
  }
}
