//theme colors for the components
@import "../plugins/forms/react-select/_react-select.scss";

$theme_names: "success", "danger", "info", "warning", "dark";

@each $theme_name in $theme_names {

  .b-#{$theme_name} {
    background-color: theme-color($theme_name) !important;
  }

  body.dark-layout{
    .theme-#{$theme_name} {
      .dropdown-language {
        a.dropdown-item {
          span:hover {
            color: theme-color($theme_name) !important;
          }
        }
      }
      a {
        &:hover {
          color: theme-color($theme_name) !important;
        }
      }
    }
  }
  .theme-#{$theme_name} {
    .primary {
      @extend .#{$theme_name};
    }
    .btn-primary {
      @extend .btn-#{$theme_name};
      color: #fff !important;
    }
    .btn-outline-primary {
      @extend .btn-outline-#{$theme_name};
    }
    .text-primary {
      @extend .text-#{$theme_name};
    }
    .vx-checkbox-primary {
      @extend .vx-checkbox-#{$theme_name};
    }
    .badge-primary {
      @extend .badge-#{$theme_name};
    }
    input:focus {
      border-color: theme-color($theme_name) !important;
    }
    .form-control:focus ~ .form-control-position svg {
      stroke: theme-color($theme_name);
    }
    .form-control:focus {
      border-color: theme-color($theme_name);
    }
    .form-label-group > input:focus:not(:placeholder-shown) ~ label,
    .form-label-group > input:not(:active):not(:placeholder-shown) ~ label,
    .form-label-group textarea:focus:not(:placeholder-shown) ~ label,
    .form-label-group textarea:not(:active):not(:placeholder-shown) ~ label {
      color: theme-color($theme_name) !important;
    }
    a:not(.dropdown-item), .dropdown .dropdown-menu .dropdown-item:hover svg, .dropdown .dropdown-menu .dropdown-item:hover span {
      color: theme-color($theme_name);
    }
    ul#main-menu-navigation .dropdown-menu a.dropdown-item.active .item-content {
      span, svg {
        color: theme-color($theme_name) !important;
      }
    }

    .header-navbar .navbar-container ul.nav li.dropdown-user .dropdown-menu-right .dropdown-item:hover {
      background-color: theme-color($theme_name);
      color: $white !important;
    }
    .dropdown-notification .dropdown-menu-header {
      background-color: theme-color($theme_name);
    }
    .dropdown-notification .dropdown-menu.dropdown-menu-right::before {
       background-color: theme-color($theme_name);
       border-color: theme-color($theme_name);
     }
    .customizer .customizer-toggle {
      background-color: theme-color($theme_name);
    }
    .nav-pills .nav-link.active {
      background-color: theme-color($theme_name);
    }
    .nav.nav-tabs .nav-item .nav-link.active {
      color: theme-color($theme_name);
      span {
        color: theme-color($theme_name);
      }
    }
    .nav.nav-tabs .nav-item .nav-link.active:after {
      background: linear-gradient(
          30deg,
          rgba(theme-color($theme_name), 1),
          rgba(theme-color($theme_name), 0.5)
      ) !important;
      box-shadow: 0 0 8px 0 rgba(theme-color($theme_name), 0.5) !important;
    }
    .header-navbar .navbar-container ul.navbar-nav li > .nav-link svg:hover {
      color: theme-color($theme_name);
    }
    .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
      background-color: theme-color($theme_name);
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: theme-color($theme_name);
      border-color: theme-color($theme_name);
    }
    // bg color lighten for rgba - opacity set
    .bg-rgba-primary {
      background: rgba(theme-color($theme_name), 0.15) !important;
    }
    .bg-primary:not(.color-box) {
      background: theme-color($theme_name) !important;
    }

    .color-default-primary {
      &:hover,&:focus {
        color: theme-color($theme_name) !important;
      }
    }

    .react-numeric-input b {
      background-color: theme-color($theme_name) !important;
    }
    .react-toggle-wrapper .react-toggle.react-toggle--checked .react-toggle-track {
      background-color: theme-color($theme_name) !important;
    }

    .header-navbar[class*="bg-"] .navbar-nav .nav-item a.dropdown-item:hover {
      background-color: theme-color($theme_name) !important;
    }
    .page-item.active .page-link{
      background-color: theme-color($theme_name) !important;
      &:hover {
        color: $white !important;
      }
    }

    .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
      background-color: theme-color($theme_name) !important;
    }

    .progress-bar{
      background-color: theme-color($theme_name);
    }
    .react-joyride__tooltip {
      background-color: theme-color($theme_name) !important;
    }

    .loading .effect-1, .loading .effect-2, .loading .effect-3 {
      border-left: 3px solid theme-color($theme_name) !important;
    }

    .loading .effect-1, .loading .effect-2, .loading .effect-3 {
      border-left: 3px solid theme-color($theme_name) !important;
    }

    .react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content,
    .react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content {
      background-color: theme-color($theme_name) !important;
    }

    .faq-tabs {
      .nav-pills .nav-link.active {
        border-color: theme-color($theme_name);
        box-shadow: 0 4px 18px -4px rgba(theme-color($theme_name), 0.95);
      }
    }

    .bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-box {
      background-color: theme-color($theme_name) !important;
    }

    .bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-label .bs-stepper-title {
      color: theme-color($theme_name) !important;
    }
  }
}


