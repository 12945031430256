// Spinners

// Sizes

.spinner-border-lg,
.spinner-grow-lg {
  height: 3rem;
  width: 3rem;
}

.spinner-border {

}

button > div.spinner-border {
  margin-right: 5px;
  vertical-align: top !important;
}
