@import "./bootstrap.scss";
@import "./components.scss";
@import "./bootstrap-extended.scss";
@import "./colors.scss";
@import "./iconfont.scss";
@import "./custom-rtl.scss";

.grecaptcha-badge {
  visibility: hidden !important;
}
