/*** React Select ***/

// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";

// Core variables and mixins overrides
@import "../../../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../../core/variables/components-variables";

$theme_names: "primary", "success", "danger", "info", "warning", "dark";

@each $theme_name in $theme_names {

  .theme-#{$theme_name} {
    .select__control,
    .React__control {
      &.select__control--is-focused,
      &.React__control--is-focused {
        border-color: theme-color($theme_name) !important;
        box-shadow: 0 0 1px 0 rgba(theme-color($theme_name), 1);
      }
    }

    .select__menu,
    .React__menu {
      .select__menu-list,
      .React__menu-list {
        .select__option,
        .React__option {
          &:hover {
            background-color: lighten($color: theme-color($theme_name), $amount: 10%);
            color: $white;
          }

          &.select__option--is-selected,
          &.React__option--is-selected {
            background-color: theme-color($theme_name) !important;
          }
          &.select__option--is-focused,
          &.React__option--is-focused{
            background-color: lighten($color: theme-color($theme_name), $amount: 10%);
            color: $white;
          }
        }
      }
    }
  }
}

.select__control,
.React__control {
  .select__single-value {
    color: inherit;
  }
}

.select__menu, .React__menu {
  z-index: 9999 !important;
}
